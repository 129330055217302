.small_window {
    min-height: 220px;
}

.order_complete{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.408px;
    color: #00BB40;
}

.order_complete_info{
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
}

.finish_window .play_window_row{
    justify-content: center;
}

.order_complete_info div{
    padding: 12px 12px 0 12px;
}
.order_complete_info div:last-child{
    padding-bottom: 12px;
}
.order_complete_info_address{
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.408px;
    color: #000000;
}


.order_complete_info_points, .order_complete_info_errors{
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.408px;
    color: #888E99;
}


.order_complete_info_errors{
    color: #FF3131;
}

.order_complete_info_text{
    padding:12px;
    text-align: center;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    opacity: 0.4;
}

.finish_window .window_close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
}
