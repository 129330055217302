.ask_about_route{
    top: calc(50% - 190px);
    z-index: 136;
    position: absolute;
    background: #fff;
    border-radius: 16px;
    width: calc(100vw - 20px);
    padding: 20px;
    margin: 10px;
    text-align: center;
}

.ask_about_route_buttons .btn{
    width: 45%;
}


.ask_about_route_buttons{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.ask_about_route_cancel{
    display: flex;
    justify-content: center;
}

.ask_about_route_cancel .btn{
    width: 70%;
}

.ask_about_route_title{
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2.4rem;
}
