
.common-registration-info-record{
    padding-top: 6px;

    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    margin: 5px 0;
    padding-left: 10px;

}
.is-one{
    border-bottom: unset !important;
    margin-bottom: unset;
}
.common-registration-info-record:nth-child(2){
    border-top: 1px solid #eee;
}

.common-registration-info-button{
    margin-bottom:   1.6rem ;
    border-radius: 5px;
    border: 1px solid #5364FF;
    padding: 15px  10px;
    font-size: 1rem;
    left: 0;
    right: 0;
}

.has_data{
    background: #fcfcfc;
}



.crib-text{
    font-size: 1.2rem;
    text-align: center;
}

.crib-plus-button {
    bottom: 0.4rem;
    width: 5.33rem;
    height: 2.33rem;
    background-color: #5364FF;
    border-radius: 5px;
    font-family: "Montserrat",sans-serif;
    font-style: normal;
    font-size: 2rem;
    color: #fff;
    font-weight: 400;
    line-height: 2.36rem;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: -6px;
}
