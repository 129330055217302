.order_block {
  position: relative;
  box-sizing: border-box;

  background: #FFFFFF;
  border-radius: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
}



.order_block_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  padding: 0.8rem;
  min-height: 88px;
  align-items: baseline;
}

.order_block_info_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.order_block_title {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.46rem;
  letter-spacing: -0.408px;
  color: #000000;
}

.order_block_points{
  color: #888E99;
  margin-top: 4px;
  font-weight: 400;
  font-size: 1rem;
  font-family: 'Fira Sans';
}

.order_block_route {
  font-weight: 500;
  line-height: 1.2rem;
  color: #888e99;
  margin-top: 0.35rem;
}

.order_block_distantion {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  letter-spacing: -0.408px;
  color: #888E99;
}

.order_block_datetime {

  text-align: right;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.408px;
  color: #5364FF;

}
.order_block_price{
  font-size: 1.4rem;
  text-align: right;
  font-weight: 700;
}

.order_block_id{
  min-width: 3.6rem;
  text-align: right;
}
.order_block_status{
  color: #00BB40;
}

.order_block_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 0.8rem;
}

.order_block_conditions ul {
  list-style:none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.order_block_conditions ul li {
  font-size: 1rem;
  color: #888e99;
}

.order_block_conditions ul li:before {
  content: '';
  display: inline-block;
  height: 2px;
  width: 2px;
  vertical-align: middle;
  border-radius:50%;
  background-color: #888e99;
  margin-right: 0.35rem;
}

.short_order_block {
  margin: 0.6rem 1rem;
  width: calc(100% - 2rem);
  max-width: 250px;
  height: 145px;

}

/*.short_order_block:first-child {*/
/*  margin-left: 1rem;*/
/*}*/

/*.short_order_block:last-child {*/
/*  margin-right: 1rem;*/
/*}*/

.short_order_block .order_block_info_right {
  min-width: 7rem;
}

.full_order_block {
  margin: 0.4rem 0;
  width: 100%;
}

.full_order_block:first-child {
  margin-top: 0;
}

.full_order_block:last-child {
  margin-bottom: 0;
}

.full_order_block .order_block_distantion {
  margin-top: 0.35rem;
}

.short_order_block .order_block_conditions ul {
  flex-direction: row;
}

.full_order_block .order_block_conditions ul {
  flex-direction: row;
}
.short_order_block .order_block_conditions ul li,
.full_order_block.order_block_conditions ul li {
  margin: 0 0.4rem;
}

.full_order_block .order_block_conditions ul li:first-child {
  margin-left: 0;
}

.full_order_block .order_block_conditions ul li:last-child {
  margin-right: 0;
}

.unviewed:before {
  content: '';

  position: absolute;
  top: -0.25rem;
  right: -0.25rem;

  background: #5364ff;
  border-radius: 50%;

  height: 1rem;
  width: 1rem;
}

