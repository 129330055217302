
.input_photo_button{
    width: 45%;
}
.photo_button_img {
    height: 4rem;
    width: auto;
    margin: 0.5rem 0.25rem;
    max-height: 60px;
    border-radius: 10px;
}
.photo_button{
    width: 100%;
    margin: 0.4rem 0;
    line-height: 3.33rem;
    border-radius: 0.5rem;
    background: #5364ff;
    position: relative;
    border: none;
    outline: none;
    text-align: center;
    font-weight: 500;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo_loader{
    margin-left: 10px;
}


