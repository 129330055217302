.make_photo_play_window .camera_box{
    top: 15px;
}
.make_photo_play_window{
    position:fixed;
    background: #3B3F49;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    z-index: 100;
    color:#fff;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}

.make_photo_play_window__title2{
    padding-top: 30px;
    font-size:16px
}
.make_photo_play_window__title{
    padding-top: 100px;
    width:100%;

}
