.yandex_route_form{
    position: fixed;
    width: 95%;
    height: 90%;
    background: #fff;
    z-index: 139;
    top: 5%;
    left: 2.5%;
    border-radius: 16px;
}


.yandex_route_form .close_button{
    box-shadow: 1px 1px 3px #888;
    position: absolute;
    bottom: 3%;
    padding: 10px 30px;
    background: #5364ff;
    color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 60%;
    text-align: center;
    font-size: 1.1rem;
}
.yandex_route_form iframe{
    border-radius: 16px;
    display: block;
}
