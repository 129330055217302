.check_phone_window {
  height: 100%;
  width: 100%;
  background: #fff;
	position: absolute;
	z-index: 35;
	top:0;
	left: 0;
	min-height: 100%;
	max-height: 100%;
}

.check_phone_container {
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(100% - 4.8rem);
  margin: 0 2.4rem;
}

.check_phone_title {
	font-size: 2.1rem;
	font-weight: 500;
	line-height: 2.5rem;
	margin-top: 30%;
}

.check_phone_text_bold {
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 1.33rem;
	align-self: flex-start;
}

.check_phone_list tr {
	height: 4rem;
}

.check_phone_list_bullet {
	vertical-align: top;
	width: 2.5rem;
}

.check_phone_list_item {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	vertical-align: top;
	padding-top: 0.15rem;
}

.check_phone_start_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.4rem 3.5rem;
	background: #5364ff;
	border-radius: 0.5rem;
	margin-top: 20%;
	margin-bottom: 20%;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.check_phone_start_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.check_phone_start_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.check_phone_label {
	width: 100%;

}

.check_phone_label_text {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
}

.check_phone_input {
	width: calc(100% - 4.8rem);
	margin: 1.33rem 2.4rem;
	height: 3.33rem;
	font-size: 1.1rem;
	color: #000;
	line-height: 1.3rem;
	text-align: center;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
}

.check_phone_input:focus {
	border: 2px solid #5364ff;
	outline: none;
}

.check_phone_check {
	position: absolute;
	top: 2.5rem;
	right: 20%;
}

.check_phone_check path {
	stroke: #00bb40;
}

.check_phone_input_container {
	position: relative;
}
.pt40{padding-top: 40px}

.check_phone_accept{
	margin: 1.33rem 2.4rem;
	display: flex;
}
.check_phone_accept_checkbox{
	width: 24px;
	height: 24px;
	border: 1px solid #C6CAD2;
	border-radius: 5px;
	display: block;
	min-width: 24px;
}
.check_phone_accept.error{
	border: red 2px solid;
	border-radius: 10px;
}
.check_phone_accept_checkbox_text a{
	color: #333;
	text-decoration: underline;
}
.check_phone_accept_checkbox_text{
	padding-left: 10px;
}
.check_phone_next_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	margin-top: 20%;
	margin-bottom: 20%;
	transition: -webkit-transform .3s ease-out;
	transition: transform .3s ease-out,-webkit-transform .3s ease-out;
}

.check_phone_window .check_phone_next_button{
	padding: 1.1rem 0;
	margin: 2.66rem 2.4rem;
}

.check_phone_next_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.check_phone_error {
	position: absolute;
	width: 100%;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
	color: #ff0000;
}

.check_phone_text_grey {
	position: absolute;
	width: 100%;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
	color: #888e99;
}

#check_phone_verify_code .button_loader,
#check_phone_send_code .button_loader{
	position: absolute;
	margin-left: -110px;
	margin-top: 6px;
}
