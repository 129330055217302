
.play_window {
  left: 0;

  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
  border-radius: 16px 16px 0px 0px;
  width: 100vw;
  top: auto;
  z-index: 36;
  background: rgb(236, 237, 239);
  display: flex;
  flex-direction: column;
  position: fixed;
  min-height: 100%;
}

.play_window_absolute{
  position: absolute;
}
.small_window{
  bottom: 0;
  min-height: 285px;
}

.full_window{
  margin-top:40px;
  background: #fff;
}

.full_window__datetime{
  font-size: 16px;
  color: #000;
  opacity: 0.54;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
}

.point_number{
  padding: 10px;
  width: 30px;
  height: 30px;
  border: 3px solid #5364FF;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5364FF;
  font-weight: bold;
}

.point_address{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-left: 9px;
}


.play_window_row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 17px 0px 17px;
}

.play_window_row_line{
  margin-left: 30px;
  /*border-left: 2px solid #9E9E9E;*/
  margin-top: -4px;
  padding-left: 22px;
  justify-content: space-between;
}

.Button45{
  min-width:47% ;
}

.Button100{
  min-width: 100%;
}

.no_border{
  border: unset;
}

.are_you_on_point_window__header{
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.408px;
  color: #000000;
}
.are_you_on_point_window{
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  background: #fff;
  align-items: inherit;
  height:280px
}
