
.startEmmitButton{
    position: fixed;
    top: 60px;
    left: 25px;
    background: #00A99F;
    border: unset;
    border-radius: 4px;
    box-shadow: 1px 1px 4px #aaa;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    padding: 8px 20px;
    z-index: 10000;
}

.startEmmitButton.pressed{
    background: #f9964e;
}
