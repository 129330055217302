.bottom_window {
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;

  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;

  width: 100vw;

  z-index: 15;
}

.bottom_window_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  position: absolute;
  top: -1.25rem;
  left: 0;
  width: 100vw;
}

.bottom_window_btn {
  background: #a8a8a8;
  opacity: 0.6;
  border-radius: 13px;
  height: 8px;
  width: 50px;
}

.bottom_window_header {
  padding: 1rem 1rem 1rem;
  padding-bottom: unset;
}

.bottom_window_filter_icon{
  position: absolute;
  float: right;
  right: 10px;
  margin-top: -28px;
}
.bottom_window_title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.94rem;
  letter-spacing: -0.408px;
  color: #000;
}

.bottom_window .bottom_window_description {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  padding-bottom: 0px;
}

.bottom_window_full_content .short_content {
  display: none;
}

.bottom_window_short_content .full_content {
  display: none;
}

.bottom_window_content {
  padding: 0 1rem 1rem 1rem;
}

.bottom_window_full_content {
  max-height: 90%;
  overflow: scroll;
}

.bottom_window_problem_link {
  color: red;
  text-decoration: underline;
  line-height: 1.25rem;
  margin-top: 1.6rem;
  text-align: center;
}


.order_window_btn {
  font-size: 1rem;
  line-height: 1.2rem;
}

.order_window_btn_referals {
  width: 100px;
  margin-right: -10px;
  font-size: 0.85rem !important;
  text-align: center;
  float: right;
  line-height: 1.1rem !important;
  letter-spacing: 0.4px;
  position: absolute;
  right: 16px;
  top: 10px;
}

.order_window_btn_chats div{
  margin-right: 10px;
}
.order_window_btn_chats {
  position: fixed;
  top: 250px;
  width: 50px;
  right: 13px;
  border-radius: 100px !important;
  background: linear-gradient(
          90deg, #00bb40 -0.15%, #45ab3c 99.85%) !important;
  border: 3px solid rgba(255, 255, 255, 0.8) !important;
  color: #fff;
}

.bottom_window_plus{
  padding-top: 10px;
}
