.next_button{
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #5364ff;
    border-radius: 0.5rem;
    -webkit-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    width: 100%;
    padding: 1.1rem 0;

    margin-top: 20px  ;
}

.next_button:active {
    -webkit-transform: translateX(0.1rem);
    transform: translateX(0.1rem);
}

.next_button_text {
    font-size: 1.33rem;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
}

.saving_button{
    background: #5364ff;
    border-radius: 0.5rem;
    -webkit-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    width: 100%;
    padding: 0.75rem 0;
    margin-top: 20px  ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saving_button div:first-child{
    margin-left: -20px;
    margin-right: 20px;
}

.saving_button .next_button_text {
    text-transform: capitalize;
}
