.quick_window {
	height: 100%;
	width: 100%;
	background: #fff;
	position: absolute;
	z-index: 100;
	top: 0;
	min-height: 100vh;
}

.quick_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: calc(100% - 4.4rem);
  margin: 0 2.2rem;
}

.quick_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin: 15% 0 0.25rem 0;
}

.quick_input {
	width: 100%;
	margin: 0.4rem 0;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
}

.quick_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.quick_input_complete {
	background: #ecedef;
}

.quick_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.quick_input_container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.quick_input_short {
	width: 48%;
}

.quick_textarea {
	width: calc(100% - 2rem);
	margin: 1rem;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	line-height: 1.2rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
	resize: none;
}

.quick_agree {
	margin-top: 1.7rem;
}

.quick_agree_check {
	vertical-align: top;
	width: 2.5rem;
}

.quick_agree_text {
	color: #000;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	vertical-align: top;
	padding-top: 0.15rem;
}

.quick_agree_link {
	color: #000;
	text-decoration: underline;
}

.quick_button {
	width: 100%;
	margin: auto 2.2rem 3.33rem 2.2rem;
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.quick_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.quick_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.quick_sending {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.33rem;
	text-align: center;
	color: #888e99;
}
