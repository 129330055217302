.datepicker_window_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 35;
  position: absolute;
}


.datepicker_window {
  position: relative;
  background: #fff;
  box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  box-sizing: border-box;
  padding: 5.12rem 0.7rem 0.7rem 0.7rem;
  width: 90vw;
  z-index: 25;
}

.datepicker {
  width: 100%;
}

.datepicker .DayPicker-wrapper {
  position: relative;
  padding: 0 !important;
  outline: none;
}

.datepicker .DayPicker-Caption {
  font-size: 1.15rem;
  line-height: 1.6rem;
  line-height: 1.6rem;
  letter-spacing: 0.38px;
}

.datepicker .DayPicker-Day {
  color: #5364ff;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.6rem;
  letter-spacing: 0.38px;
  padding: 0.5rem;
  outline: none;
}

.datepicker .DayPicker-Day--disabled {
  color: #c6cad2;
}

.datepicker .DayPicker-Weekday {
  color: rgba(60, 60, 67, 0.3);
  font-size: 0.85rem;
  text-transform: uppercase;
}

.datepicker .DayPicker-Month {
  margin: 0 !important;
  width: 100%;
}

.datepicker_navbar {
  position: absolute;
  top: 0.26rem;
  right: 1.6rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 3.3rem;
}

.datepicker_day {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 2rem;
  width: 2rem;
}

.datepicker_day_off {
  background: rgba(0, 255, 0, 0.3);
  border-radius: 50%;
  color: #fff;
}

.datepicker_info {
  padding: 1rem 0.5rem;
}

.datepicker_info_item {
  color: #000;
  opacity: 0.4;
  text-align: center;
  margin-bottom: 0.66rem
}

.datepicker_info_item:last-child {
  margin-bottom: 0;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent !important;
}

.datepicker_month_change, .datepicker_year_change{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;

}
.datepicker_month_change .datepicker_navbar_item, .datepicker_year_change .datepicker_navbar_item{
  padding-right: 15px;
  padding-left: 15px;
}

.datepicker_change_my{
  margin-top: -90px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  width: 165px;
  position: absolute;
}
