

.input_checkbox_item{
    width: 24%;
    min-width: 70px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}


.input_checkbox_item.short{
    width: 48% !important;
    margin: 5px 0 !important;
}

.full-width.input_checkbox_item{
    width: 100%;
    text-align: center;
    justify-content: center;
}

.input_checkbox_item input{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.input_checkbox_item_label{
    font-size: 1.1rem;
}

