.time_btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 10;

    height: 3.25rem;
    padding: 0px 1rem;

    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    background: #3B3F49;
    width: 160px;
    color: #fff;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;

}

.two_text{
    margin-right:10px
}
.two_points{
  margin-top: -3px;
}
.two_digits, .two_points{
    text-align: center;
    font-size:21px;
    min-width:9px;
}
.navigator_btn{
    background: #fff;
    position: absolute;
    bottom: 237px;
    left: 1rem;
    z-index: 10;
    cursor: pointer;
    transition: 0.8s;
    border-radius: 100px;
}
.navigator_btn.active{
    transform: rotate(-45deg);
    background: #3B3F49;
}

.imitation_btn{
    text-align: center;
    position: absolute;
    top: 70px;
    right: 1rem;
    z-index: 10;
    padding: 8px 5px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 25%);
    cursor: pointer;
    background: #ef781d;
    width: 160px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-size: 13px;

}


