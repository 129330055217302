.screen_help {
  background: #e5e5e5;
  box-sizing: border-box;
  padding: 1.6rem;
  height: 100%;
  width: 100vw;
  z-index: 35;
  position: fixed;
  top: 0;
  overflow: auto;
}

.screen_help_header {
  font-size: 1.35rem;
  font-weight: 700;
  text-align: center;
}

.screen_help_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.9rem 0;
}

.screen_help_article {
  background: #fff;
  border-radius: 8px;
  margin: 0.5rem 0;
  width: 100%;
}

.screen_help_article_title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.35rem;
  padding: 0.8rem;
}

.screen_help_article_description {
  padding: 0 0.8rem 0.8rem;
  line-height: 1.35rem;
}

.screen_help_article_footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
}

.screen_help_article_footer .screen_help_article_btn {
  color: #5364ff;
  font-weight: 500;
  line-height: 1.2rem;
  text-decoration: none;
}

.article_window {
  background: #fff;
  box-sizing: border-box;
  padding: 1.6rem;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  overflow: auto;
}

.article_window_header {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
}

.article_window_content {
  margin-top: 2.5rem;
}

.screen_help .loader{
  background: #ffff;
  z-index: 100;
  padding: 40px;
  width: 70%;
  border-radius: 20px;
}
