.screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  z-index: 35;
  position: fixed;
  width: 100%;
  background: #ECEDEF;
  top:0;
}

.screen_header {
  padding: 4.5rem 1.6rem 1.6rem;
}

.screen_title {
  color: #000;
  font-weight: 700;
  font-size: 1.35rem;
  line-height: 1.45rem;
}

.screen_status {
  color: #888e99;
  margin-top: 0.65rem;
  line-height: 1.2rem;
}
