.checkbox {
	display: flex;
	align-items: center;
	position: relative;
	user-select: none;
	width: 1.6rem;
	height: 1.6rem;
}

.checkbox_input {
	display: block;
	position: absolute;
	opacity: 0;
}

.checkbox_input:checked ~ .checkbox_mark::after {
	display: block;
}

.checkbox_mark {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	border: 1px solid #c6cad2;
	border-radius: 20%;
}

.checkbox_input:checked ~ .checkbox_mark {
	border: 1px solid #5364ff;
	background: #5364ff;
}

.checkbox_mark::after {
	content: url("data:image/svg+xml,%3Csvg viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.230769 8.76922C0.0769231 8.61537 0 8.3846 0 8.23075C0 8.07691 0.0769231 7.84614 0.230769 7.69229L1.30769 6.61537C1.61538 6.30768 2.07692 6.30768 2.38462 6.61537L2.46154 6.69229L6.69231 11.2308C6.84615 11.3846 7.07692 11.3846 7.23077 11.2308L17.5385 0.538447H17.6154C17.9231 0.230755 18.3846 0.230755 18.6923 0.538447L19.7692 1.61537C20.0769 1.92306 20.0769 2.3846 19.7692 2.69229L7.46154 15.4615C7.30769 15.6154 7.15385 15.6923 6.92308 15.6923C6.69231 15.6923 6.53846 15.6154 6.38462 15.4615L0.384615 8.99999L0.230769 8.76922Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
	position: absolute;
	display: none;
	top: 0;
	left: 50%;
	width: 50%;
	height: 30%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
