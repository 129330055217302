.response_window {
  height: 100vh;
  width: 100%;
  background: #fff;
  position: fixed;
  z-index: 1000;
}

.response_container {
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.response_success {
	position: relative;
	width: 4rem;
	height: 4rem;
	background: #00bb40;
	border-radius: 50%;
}

.response_success::after {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1.8rem;
	height: 1.2rem;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	content: url("data:image/svg+xml,%3Csvg viewBox='0 0 31 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.75 2.5L11.25 20L2.5 11.25' stroke='%23FFFFFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
}

.response_success_text {
	font-size: 1.33rem;
	line-height: 1.33rem;
	font-weight: 500;
	color: #00bb40;
	margin-top: 1.1rem;
}

.response_success_message {
	font-size: 1.6rem;
	line-height: 2rem;
	font-weight: 500;
	color: #000;
	margin-top: 2.5rem;
	text-align: center;
}

.response_error {
	position: relative;
	width: 4rem;
	height: 4rem;
	background: #ff3131;
	border-radius: 50%;
}

.response_error::after {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1.2rem;
	height: 1.2rem;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	content: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.5L19.5 19.5M19.5 2.5L2.5 19.5' stroke='%23FFFFFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
}

.response_error_text {
	font-size: 1.33rem;
	line-height: 1.33rem;
	font-weight: 500;
	color: #ff3131;
	margin-top: 1.1rem;
}

.response_error_message {
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 400;
	color: #000;
	margin-top: 2.5rem;
	text-align: center;
}
