


.remove_record{
    position: absolute;
    width: calc(100% - 2.5rem);
    text-align: right;
}

.remove_record span{
    color: red;
    text-decoration: underline;
}
