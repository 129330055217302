@media screen and (min-width: 375px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 374px) {
  html {
    font-size: 13px;
  }
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

body {
  background: #ecedef;
  margin: 0;
  font-family: 'Fira Sans';
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
}
*{
  user-select: none;
}
.page_back_link {
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
}

.page_title {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.466rem;
}
.page_title_2 {
  font-size: 0.9rem;
  padding: 10px 0 ;
  color: #aaa;
}

.window_close_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0.1rem;
  right: 0;
  background: #c6cad2;
  border-radius: 50%;
  height: 2.4rem;
  width: 2.4rem;
  float: right;
}

.window_close_btn svg {
  position: relative;
  top: 0.1rem;
}
.H_btn{
  border-radius: 100px !important;
  margin: 6px 0;
}
.H_l_horizontal .H_grp .H_btn, .H_l_vertical .H_ctl{
  background: transparent;
  box-shadow: unset;
}

.isIOS-device{
  position: fixed;
  left: 10px;
  top: 60px;
  font-weight: bold;
  color: black;
}


.isIOS-device button{
  position: fixed;
  left: 10px;
  top: 120px;
  font-weight: bold;
  color: black;
  background: #ffffff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 3px 8px;
}
.isIOS-device button.selected{
  background: #00A99F50;
}

.isIOS-device button:nth-child(2){ top: 150px;}
.isIOS-device button:nth-child(3){ top: 180px;}
.isIOS-device button:nth-child(4){ top: 210px;}
.isIOS-device button:nth-child(5){ top: 240px;}

.isCheckDevice{
  background: #f59b4c;
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  /* width: 80%; */
  z-index: 11;
  padding: 2px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  /* border-radius: 4px; */
  color: #fff;
  box-shadow: 1px 1px 19px #aaa;
  opacity: 0.9;
}



.pulsar-sphere::after {
  display: flex;
  background: #00000050;
  border-radius: 300px;
  height: 250px;
  width: 250px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
  content: "";
  z-index: -1;

  margin-top: -125px;
  margin-left: -125px;

}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }

}