.chat_main_button{
    position: fixed;
    top: 250px;
    right: 13px;
    height: 50px;
    width: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: 100px !important;
    background: linear-gradient(
            90deg, #00bb40 -0.15%, #45ab3c 99.85%) !important;
    border: 3px solid rgba(255, 255, 255, 0.8) !important;
    color: #fff;
}

.btn.chat_main_button  svg {
      margin-left: unset;
    /* margin-top: 5px; */
}

.chat_main_button-point:before {
    content: '!';
    position: absolute;
    top: 30px;
    right: 0px;
    background: #ff9800;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    /* border: 1px solid #0a8c2d; */
    text-align: center;
    /* padding: unset; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f6ff53;
}
