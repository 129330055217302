.driver_update_form{
    position: absolute;
    top: 25px;
    z-index: 36;
    border-radius: 10px ;
    left: 5px;
    right: 5px;
    min-height: calc(100% - 50px);
    /* overflow-y: auto; */
    padding-bottom: 25px;
    display: flex;
}

.driver_update_form_container{
    padding: 15px;
    background: #fff;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px ;
}

.driver_update_form_buttons .window_close_btn{
    position: absolute;
    right: 10px;
    top: 10px;

}

.driver_update_form_title{
    padding: 30px 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

.driver_update_form_values{
    flex-grow: 3;
}
.driver_update_form_values_flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}
.driver_update_form_buttons{
    padding: 50px 0px 20px 0;
}


.selector-yes-no-block{
    width: 100%;
    font-size: 16px;
    padding: 10px 0;
    border: 1px solid #eee;
    margin: 5px 0;
    border-radius: 4px;
}

.selector-yes-no-block_buttons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.selector-yes-no-block_title{
    padding-bottom: 15px;
    text-align: center;
    font-weight: 500;
}
.selector-yes-no-block_buttons div{
    background: #fff;
    border: 1px solid #bbb;
    color: #888;
    border-radius: 4px;
    padding: 5px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-yes-no-block_date  .input_filtered_container_name,
.selector-yes-no-block_date  input{
    text-align: center;
}
.selector-yes-no-block_date  {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 10px;
}

.selector-yes-no-block_buttons div.active{
    background: #5364ff;
    color: #fff;
}

.error_characteristic{
    color: red;
    padding: 10px;
    width: 100%;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 4px;
}
