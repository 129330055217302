.top_gradient {
  position: fixed;
  width: 100%;
  height: 8.25rem;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #FFFFFF 22.4%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
  pointer-events: none;
}

.here_map {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.H_zoom{
  top: 130px;
  position: fixed;
  right: 10px;
}

.here_icon {
  position: absolute;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.here_icon_caption {
  display: flex;
  flex-direction: row;
  justify-content: center;

  background: #5364ff;
  border-radius: 8px;

  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 2.4rem;

  padding: 0 0.5rem;
  height: 2.4rem;
  width: 100%;
} 

.here_icon_distance {
  color: rgba(255, 255, 255, 0.5);
}

.ymap_icon_price {
  margin-left: 0.5rem;
}

.ymap_icon_placemark {
 position: relative;
 top: 0.25rem;
}


.marker-number, .marker-number-2{
  font-family: 'Fira Sans';
  position: absolute;
  z-index: 5;
  margin-left: -25px;
  margin-top: -25px;
  background: #5364FF;
  color: #fff;
  font-weight: 500;
  padding: 5px 12px;
  font-size: 12px;
  border-radius: 7px;
  min-width: 23px;
  text-align: center;
  white-space: nowrap;
  box-shadow: unset;
}

.marker-number-2{
  background: #fff;
  width: 180px;
  height: 100px;
  /* max-height: 120px; */
  overflow: hidden;
  margin-top: -105px;
  margin-left: -80px;
  color: #000;
  font-weight: 100;
  text-align: left;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 1px 1px 8px #aaaaaa50;
  border-radius: 10px;
}

.marker-number-hidden{
  display: none;
}

.marker-distance, .marker-time{
  color: #aaa;
  font-weight: 100;
  margin-top: 5px;
}

.marker-time{
  margin-top: unset;
}
/*.marker-number:before{*/
/*  content: '';*/
/*  position: absolute;*/
/*  z-index: -1;*/
/*  width: 10px;*/
/*  height: 10px;*/
/*  background-color: #ffffff;*/
/*  transform: translateX(-8px) rotate(45deg);*/
/*}*/
.marker-text{
  display: none;
}
.marker-time-orange, .marker-time-blue{
  width: 300px;
}
.marker-time-orange{
  position: absolute;
  z-index: 5;
  color: #FFFFFF;
  margin-left: 30px;
  background: #FF9900;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  min-width: 23px;
  text-align: center;
}

.marker-time-blue{
  position: absolute;
  z-index: 5;
  color: #FFFFFF;
  margin-left: 30px;
  background: #5364FF;
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  min-width: 23px;
  text-align: center;
}


.debug_info div:first-child{
  color: blue;
}
.debug_info{
  position: fixed;
  left: 10px;
  top: 100px;
  text-align: center;
  text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff;
}

.button-locator{
  position: fixed;
  top: 75px;
  right: 13px;
  height: 50px;
  width: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px !important;
  background: linear-gradient( 180deg, #f17474 -0.15%, #ad1414 99.85%) !important;
  /*border: 1px solid rgba(255, 255, 255, 0.8) !important;*/
  color: #fff;
  line-height: 13px;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  z-index: 5;
  padding-top: 2px;
}