.photo_buttons_content_title{
    text-align: center;
    padding-bottom: 10px;
    font-size: 1.1rem;
}
.photo_buttons_content_buttons{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.photo_buttons_container{
    background: #f9f9f9;
    margin: -5px -10px;
    padding: 5px 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
}
.photo_buttons_container .btn{position: relative;padding-left: 20px;}

.photo_buttons_container .btn.btn_small.btn_green{     padding-left: 40px; max-width: 47%;}

.photo_buttons_container .btn.btn_small:before{
    content: ' ';
    position: absolute;
    left: 8px;
    top: 16px;
    background: url("../../App/svg/photo2.svg") no-repeat;
    width: 20px;
    height: 20px;
}