.order_book_page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    z-index: 35;
    position: fixed;
    width: 100%;
    top:0;
}

.orders_list{
    overflow: scroll;
    height: 100%;
}

.order_book_header {
    background: #fff;
    padding: 1.6rem 1.6rem 1rem;
}

.order_book_period_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
}

.order_book_period_title {
    font-weight: 500;
    color: #888E99;
}

.order_book_period_info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    color: #5364ff;
}

.order_book_container {
    background: #ecedef;
    height: 100%;
    overflow:auto;
}

.order_book_money {
    padding: 1.6rem;
}

.order_book_debit_credit {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -0.8rem;
}

.order_book_item {
    margin: 0 0.8rem;
}

.order_book_item_name {
    color: #888e99;
}

.order_book_item_value {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2.5rem;
}

.order_book_debit .order_book_item_value,
.order_book_credit .order_book_item_value {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3rem;
}

.order_book_debit .order_book_item_value,
.order_book_price .order_book_item_value {
    color: #5364ff;
}

.order_book_price,
.order_book_distance {
    margin-top: 1.6rem;
}

.order_book_truck {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1.6rem;
}



.orders_tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;
    margin-top: 20px;
}

.orders_tab {
    position: relative;

    cursor: pointer;
    background: #c6cad2;
    border-radius: 8px;
    padding: 0.4rem 0.8rem;

    font-weight: 500;
    line-height: 1.466rem;
    color: #fff;

    text-transform: uppercase;
    margin-left: 0.8rem;
}

.orders_tab:first-child {
    margin-left: 0;
}

.orders_tabs .active {
    background: #3b3f49;
}

.loader{
    position: fixed;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    text-align: center;
}

.loader-text{
    font-style: italic;
    font-family: 'FiraGO', 'Fira Sans', sans-serif;
    font-size: 1.4rem;
    margin-top: 1rem;
}


.order_book_page .order_book_container .short_order_block {

    max-width: unset !important;
    height: unset !important;
    overflow: unset;
}

.order_book_collapse{
    border-bottom: 1px solid #C6CAD2
}
.order_book_collapse_title{
    width: calc(100%);
    padding: 16px 28px;
    color: #888E99;
    font-size: 15px;
    font-weight: 500;

}


.order_book_collapse_icon_up, .order_book_collapse_icon{
    background-image: url('../../images/arrow-down.svg') ;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    float: right;
}
.order_book_collapse_icon_up{
    background-image: url('../../images/arrow-up.svg') ;
}

.hidden_orders{
    display: none;
}
