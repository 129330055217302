
.very-quick-registration-form{
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background: #fff;
    position: fixed;
    z-index: 111;
    min-height: 100vh;
    overflow: scroll;
}

.very-quick-registration-form-container .check_phone_error{

    position: unset;
    padding-bottom: 20px;
    margin: -0.78rem 1.2rem;
    width: unset;
}

.very-quick-registration-form-container .check_phone_label_text{
    text-align: left;
    padding-left: 5px;
    padding-top: 20px;
}
.very-quick-registration-form-container .button_loader{

    margin-left: -120px !important;
    margin-top: 1px !important;
}
.very-quick-registration-form-container .check_phone_input{
    margin: 1rem 0;
    width: 100%  ;
    text-align: left;
    padding-left: 20px;
}

.very-quick-registration-form-container{
    padding: 10px 15px;
}
.very-quick-registration-form__title{
    text-align: center;
    font-size: 22px;
    padding: 60px 30px;
    font-weight: 500;
}

.check_phone_phone_left{
    text-align: left;
    padding-left:10px ;
    padding-right:10px ;
}


.very-quick-registration-form-container .check_phone_block .check_phone_label_text{
    margin-left: unset;
    padding-top: 5px;
}
.very-quick-registration-form-container .check_phone_block .check_phone_input{
    min-width: 120px;
    margin-top: unset;
}

.very-quick-registration-form-container .check_phone_block   .check_phone_check{
    top:15px;
    right: 10px !important;
}


.check_phone_block{
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
}
.check_phone_block.hidden{
    opacity: 0;

}

.check_phone_check{
    top:2.1rem
}


.very-quick-registration-form-container .input_filtered_container_name{
    font-size: 14px;
}
.very-quick-registration-form-container .check_phone_next_button{
    bottom: 10px;
    width:  100%  ;
    position: unset;
}
