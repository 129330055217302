.menuHeaderWrapper {
    height: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 7%;
}

.menuHeaderWrapper .header {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.408px;
}
.menuHeaderWrapper .order {
    font-size: 15px;
    letter-spacing: -0.408px;
    color: #000000;
    opacity: 0.54;
}

.menuBodyWrapper {
    overflow-y: auto;
    height: 85%;
}
.menuBodyWrapper .menuBodyItemWrapper {
    min-height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7%;
    border-top: 1px solid #B7BBC4;
}

.menuBodyWrapper .itemHeader {
    font-size: 16px;
    line-height: 22px;
}
.menuBodyWrapper .itemDetails {
    font-size: 14px;
    line-height: 22px;
    color: #888E99;
}
.menuBodyWrapper .iconWrapper {
    text-align: right;
}
.menuBodyWrapper .iconWrapper img {
    margin-left: 10px;
}

.dialogHeaderWrapper{
    height: 60px;
}

.dialogHeader{
    display: flex;
    width: 100%;
}

.dialogHeader .header{
    font-size: 20px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.menuHeaderWrapperGroup .header{
    width: 100%;
    text-align: center;
    font-size: 23px;
    color: #777;
}

.itemDetails.hidden{
    display: none;
}
.itemDetailsTime{
    font-size: 10px;
    margin-left: 5px;
}
