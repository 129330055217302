.registration_driver_data_window {
    height: 100%;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 35;
    top: 0;
    overflow: auto;
}

.driver_container_m {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: unset;
    margin: 0 1rem !important;
    padding-top: 20px !important;

}

.splitter{
    width: 20px;
}

.input_container_left{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    width: 100%;
}

.driver_input_container_many_items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    flex-wrap: wrap;
}

.driver_2input_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}

.padding-bottom-10{
    padding-bottom: 10px;
}

.registration_driver_data_window .sub_title.link{
    text-decoration: underline;
    color: dodgerblue;
}

.registration_driver_data_window .sub_title.sub_title_delete{
    font-size: 1.1rem;
    text-decoration: underline;
    color: crimson;
}
.registration_driver_data_window .sub_title{
    padding: 10px 0;
    text-align: center;
    font-size: 1.2rem;
}

.flex-start{
    justify-content: flex-start;
}
