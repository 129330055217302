.middle_registration_form .page_title{
    text-align: center;
    margin-left: -6px;
}
.middle_registration_form .page_title_2{
    padding: 30px;
    margin-left: 0;
}

.middle_registration_form .input_filtered_full{
    margin-bottom: 20px;
}
.middle_registration_form .driver_input_container_many_items{
    margin: 10px 0;
}
