.waiting_window{
    top: calc(50% - 100px);
    z-index: 136;
    position: absolute;
    background: #fff;
    border-radius: 16px;
    width: calc(100vw - 20px);
    padding: 20px;
    margin: 10px;
    text-align: center;
}
