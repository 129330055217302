.partnership_window {
	height: 100vh;
	width: 100%;
	background: #fff;
	position: absolute;
	z-index: 35;
	top: 0;
}

.partnership_container {
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.partnership_title {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 1.5rem;
	text-align: left;
	width: calc(100% - 3.2rem);
	margin: 15% 1.6rem 1.2rem 1.6rem;
}

.partnership_body {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 1;
	background: #ecedef;
}

.partnership_body_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 1.2rem 1.6rem;
}

.partnership_body_text {
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	color: #000;
	text-align: left;
	width: 100%;
	margin: 0.9rem 0;
}

.partnership_body_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #5364ff;
	border: 3px solid rgba(255, 255, 255, 0.3);
	border-radius: 0.5rem;
	width: 100%;
	height: 4.3rem;
	margin: 0.4rem 0;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.partnership_body_button_orange {
	background: linear-gradient(90deg, #ff8c22 -0.15%, #ff5d53 99.85%);
}

.partnership_body_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.partnership_body_button_text {
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.partnership_button_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	margin: 2rem 0;
}

.partnership_section_button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 2.25rem;
	padding: 0 0.8rem;
	margin: 0 0.67rem;
	background: #c6cad2;
	border-radius: 0.5rem;
}

.partnership_section_button_selected {
	background: #3b3f49;
}

.partnership_section_button_empty {
	position: relative;
}

.partnership_section_button_empty::after {
	content: '';
	position: absolute;
	right:0;
	top: 0;
	width: 0.9rem;
	height: 0.9rem;
	background: #5364ff;
	border-radius: 50%;
	-webkit-transform: translate(33%, -33%);
	transform: translate(33%, -33%);
}

.partnership_section_button_text {
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.partnership_referrals {
	width: 100%;
	margin-bottom: 6%;
	font-size: 0.9rem;
	line-height: 1.2rem;
	border-collapse: collapse;
}

.partnership_referrals tr {
	height: 3rem;
}

.partnership_referrals_phone {
	width: 36%;
	padding-left: 1.6rem;
	color: #000;
}

.partnership_referrals_status {
	width: 40%;
	padding-left: 1.6rem;
	color: #888e99;
}

.partnership_referrals_status_registered {
	color: #00bb40;
}

.partnership_referrals_sum {
	padding: 0 1.6rem;
	color: #c6cad2;
}

.partnership_referrals_sum_registered {
	color: #000;
	font-weight: 500;
}

.partnership_referrals thead th {
	color: #888e99;
	font-weight: 400;
	text-align: left;
}

.partnership_referrals tbody tr {
	background: #fff;
}

.partnership_referrals_row {
	box-shadow: inset 1px 1px 0px #c6cad2;
}

.partnership_referrals tbody th {
	font-weight: 400;
}

.partnership_referrals_text {
	background: transparent!important;
	color: #888e99;
}

.partnership_subcontainer {
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 0 2.2rem;
}

.partnership_subtitle {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.6rem;
	text-align: left;
	width: 100%;
	margin-top: auto;
	margin-bottom: 1.2rem;
}

.partnership_input {
	width: 100%;
	margin: 0.4rem 0;
	height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: row;
	padding: 0 1rem;
}

.partnership_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.partnership_input_complete {
	background: #ecedef;
}

.partnership_input_text {
	width: 100%;
	font-family: 'Fira Sans';
	font-size: 1.1rem;
	text-align: left;
	border: none;
	outline: none;
	background: transparent;
}

.partnership_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 3.6rem;
	margin: auto 0 3.33rem 0;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.partnership_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.partnership_button_text {
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.partnership_sending {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 1.33rem;
	text-align: center;
	color: #888e99;
	width: 100%;
	margin: auto 0 3.33rem 0;
}

.partnership_response_window {
  height: 100vh;
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}

.partnership_response_container {
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1.6rem;
}

.partnership_response_success {
	position: relative;
	width: 4rem;
	height: 4rem;
	background: #00bb40;
	border-radius: 50%;
	margin-top: auto;
}

.partnership_response_success::after {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 1.8rem;
	height: 1.2rem;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	content: url("data:image/svg+xml,%3Csvg viewBox='0 0 31 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.75 2.5L11.25 20L2.5 11.25' stroke='%23FFFFFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
}

.partnership_response_success_text {
	font-size: 1.33rem;
	line-height: 1.33rem;
	font-weight: 500;
	color: #00bb40;
	margin-top: 1.1rem;
}

.partnership_response_success_message {
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 400;
	color: #000;
	margin-top: 3rem;
	text-align: center;
}
.partnership_body_button_driver_and_client{
	display: flex;
	justify-content: space-evenly;
	padding: 20px 20px 0px;
}
.partnership_body_button_driver{


	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;

	position: static;
	width: 94px;
	height: 34px;
	left: 0px;
	top: 0px;

	background: #C6CAD2;
	border-radius: 8px;
	color: #fff;
}
.partnership_body_button_driver-active{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;

	position: static;
	width: 94px;
	height: 34px;
	left: 0px;
	top: 0px;

	background: #3B3F49;
	border-radius: 8px;
	color: #fff;
}
