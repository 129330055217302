.common_form_window {
    height: 100%;
    width: 100%;
    background: #fff;
    position: fixed;
    z-index: 35;
    top: 0;
    overflow: auto;

}

.common_form_window_icon{
    width: 60px;
    text-align: center;
    margin-right: -5px;
}

.common_form_window_content{
    padding-top: 0px;
    margin: 0 1.2rem;
    padding-bottom: 30px;
}
.common_form_window_title{
    display: flex;
    padding-top: 20px;
    justify-content: flex-start;
    align-items: center;
}

.common_form_window_title .page_title {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.466rem;
    width: calc(100% - 100px);
    padding: unset;
    text-align: left;
    letter-spacing: -0.44px;
}

.common_form_window_title .page_title_center {
    text-align: center;
}

.common_form_window_title .page_title_2{
    font-size: 1rem;
    padding: 10px 5px;
    text-align: center;
    width: 100%;
    color: #666;
    font-weight: 100;
}

 .common_form_no_tin{
     padding: 10px;
 }
 .common_form_tin{
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 1.2rem;
    text-align: center;
}
.common_form_window_title .owner_select_footer{
    padding-top: 20px;
}


