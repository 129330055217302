.photo-taker_container {
	width: 100%;
	height: 100%;
	background: #3b3f49;
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 35;
}

.photo-taker_title {
	margin: 5% 1.6rem 1rem 1.6rem;
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 2rem;
	color: #fff;
	position: relative;
	z-index: 2;
	text-align: center;
}

.photo-taker_from_disk {
	color: #fff;
	z-index: 2;
	background: #5364FF;
	padding: 10px 20px;
	border-radius: 5px;
}
.photo-taker_frame {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.photo-taker_subtitle {
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 4rem;
	color: #fff;
	z-index: 2;
	height: 4rem;
	padding-top: 11px;
}

.photo-taker_video {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.photo-taker_video::-webkit-media-controls-play-button {
	display: none;
	-webkit-appearance: none;
}

.photo-taker_box {
	position: relative;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 1;
	box-shadow: 0 0 0 600px rgba(59, 63, 73, 0.8);
}

.photo-taker_box_border {
	border: 2px solid #5364ff;
	border-radius: 0.5rem;
}

.photo-taker_show_camera {
	background: #5364ff;
	border-radius: 0.5rem;
	margin: 0 1rem;
}

.photo-taker_show_camera_text {
	margin: 0 1.6rem;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 3.6rem;
	color: #fff;
	text-align: center;
}

.photo-taker_show_camera_text path {
	fill: #fff;
}

.photo-taker_error {
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 3.6rem;
	color: #fff;
	text-align: center;
}

.photo-taker_image_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 6rem;
	z-index: 2;
}

.photo-taker_image {
	height: 4rem;
	width: auto;
	margin: 0.5rem 0.25rem;
}

.photo-taker_container .window_close_btn{
	z-index: 2;
	top: 1.66rem;
	background: #bfbfbf;
	position:absolute;
	right:15px;
}


