
.driver_status_window {
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;

  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;

  padding: 1rem 1rem 0.8rem;
  width: 100vw;

  z-index: 35;
}

.driver_status_window_title {
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.94rem;
  letter-spacing: -0.408px;
  color: #000;
  text-align: center;
  padding: 0.5rem 0;
}

.driver_status_window_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.driver_status_window_content .btn {
  margin-top: 1.6rem;
}

.driver_status_window_problem {
  color: red;
  text-decoration: underline;
  line-height: 1.25rem;
  margin-top: 1.6rem;
}

.driver_status_window_info {
  color: #000;
  opacity: 0.4;
  margin-top: 1rem;
  text-align: center;
}
