.close_route_window{
    top: auto;
    z-index: 36;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    background: #fff;
    border-radius: 16px 16px 0px 0px;
    width: 100vw;
    padding: 20px;
}
