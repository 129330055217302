.foldable_block_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;

  background: #888e99;
  color: #fff;
  padding: 0 1.6rem;
  height: 2.8rem;
}

.foldable_block_icon {
  position: absolute;
  right: 1.6rem;
}

.foldable_block_content_closed {
  line-height: 0;
  max-height: 0;
  overflow: hidden;
}