.owner_select_window {
  height: 100%;
  width: 100%;
  background: #fff;
	position: fixed;
	z-index: 35;
	top: 0;
	overflow: auto;
	min-height: 100vh;
}

.owner_select_container {
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: calc(100% - 3.2rem);
  margin: 0 1.6rem;

}

.owner_select_title {
	font-size: 1.33rem;
	font-weight: 500;
	line-height: 2rem;
	text-align: center;
	width: 100%;
	margin: 2.4rem 0;
}

.owner_select_tile_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 0.8rem;
}

.owner_select_tile {
	width: 47%;
	height: 12rem;
	border: 1px solid #5364ff;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.owner_select_tile_selected {
	background: #5364ff;
	position: relative
}

.owner_select_tile_selected::after {
	content: " ";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -1rem;
	border-width: 1rem;
	border-style: solid;
	border-color: #5364ff transparent transparent transparent;
}

.owner_select_tile_deselected {
	border: 1px solid #c6cad2;
}

.owner_select_tile_subcontainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 50%;
	margin: 1.6rem 0;
}

.owner_select_tile_text {
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 1.33rem;
	text-align: center;
	width: 80%;
	color: #5364ff;
}

.owner_select_tile_selected .owner_select_tile_text {
	color: #fff;
}

.owner_select_tile_deselected .owner_select_tile_text {
	color: #c6cad2;
}

.owner_select_tile_selected path {
	fill: #fff;
}

.owner_select_tile_deselected path {
	fill: #c6cad2;
}

.owner_select_input {
	width: calc(100% - 1.6rem);
	margin: 1.6rem 0.8rem 0 0.8rem;
	line-height: 3.33rem;
	border: 1px solid #c6cad2;
	border-radius: 0.5rem;
	position: relative;
}

.owner_select_input:focus-within {
	border: 2px solid #5364ff;
	outline: none;
}

.owner_select_input_complete {
	background: #ecedef;
}

.owner_select_input_disabled {
	background: #ecedef;
	pointer-events: none;
}

.owner_select_input_text {
	width: calc(100% - 2rem);
	margin: 0 1rem;
	font-size: 1.1rem;
	text-align: center;
	border: none;
	outline: none;
	background: transparent;
}

.owner_select_link {
	width: 100%;
	color: #888e99;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5rem;
	text-align: center;
	text-decoration: underline;
	margin: 2rem 0;
}

.owner_select_footer {
	margin: 1rem 1.6rem 5rem 1.6rem;
}

.owner_select_agree {
	margin-bottom: 2rem;
}

.owner_select_agree_check {
	vertical-align: top;
	width: 2.5rem;
}

.owner_select_agree_text {
	color: #000;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	vertical-align: top;
	padding-top: 0.15rem;
}

.owner_select_agree_link {
	color: #000;
	text-decoration: underline;
}

.owner_select_button {
	display: flex;
	align-self: center;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 1.1rem 8.4rem;
	background: #5364ff;
	border-radius: 0.5rem;
	-webkit-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
}

.owner_select_button:active {
	-webkit-transform: translateX(0.1rem);
	transform: translateX(0.1rem);
}

.owner_select_button_text {
	font-size: 1.33rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
}

.owner_select_error {
	width: 100%;
	margin: 2rem 0;
	font-size: 1.1rem;
	font-weight: 400;
	line-height: 1.33rem;
	text-align: center;
	color: #ff0000;
}
