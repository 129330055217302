
.on_place_window{
    min-height:100%;
    position: absolute !important;
}
.order_play_info_on_place_title{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.408px;
    text-transform: uppercase;
    color: #000;
}

.order_play_info_on_place_time{
    font-size: 16px;
    padding: 10px 0;
}


.on_place_window__photo{
    display: flex;
    justify-content: center;
    align-items: center;
}
.driver_status_window_problem{
    text-align: center;
}


.order_info_comment{
    padding: 20px 0;
    font-size: 18px;
    color: darkgreen;
}

.on_place_window  .order_info_persons{
    border-radius: 8px 8px 0 0;
    margin: 12px 0;
    margin-bottom: 0;
}

.schema_play_window .order_play_info_on_place_title{
    text-transform:unset;
    padding-right: 50px;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: -0.408px;
}
.schema_play_window .window_close_btn{
    position: absolute;
    right: 23px;
    top: 23px;
}


.schema_play_window{
    min-height:300px !important;
    bottom:0;
}


.schema_play_window .schema_play_window__cargo__loading,
.schema_play_window .schema_play_window__cargo__error{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    text-shadow: 1px 1px #fff
}

.schema_play_window .schema_play_window__cargo__error{
    color:red;
}
.schema_play_window .schema_play_window__cargo{
    background: #ECEDEF;
    border: 1px solid #C6CAD2;
    box-sizing: border-box;
    min-height: 140px;
    width:100%;
    position:relative
}

.cargoSizeBlock {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    padding: 1px;
}
.cargoSizeBlock .sizeBlock {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    /*background: #888E99;*/
    background: #5364FF;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.cargoSizeBlock .sizeBlockValue {
    transform: scale(1, -1);
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.408px;
}
.cargoSizeBlock .sizeBlockValue:first-child {
    position: absolute;
    top: calc(50% - 9px);
    right: 10px;
    height: 15px;
}
.cargoSizeBlock .sizeBlockValue:first-child div{
    transform: rotate(-90deg);
}

.person_button.first{
    margin-left: 10px;
}
