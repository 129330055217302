.menuBodyWrapperChatMessages{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: calc(100% - 140px);
    padding-bottom: 20px;
}

.chat_messages_scroll_container{
    overflow-y: auto;
}
.chat_message_container{
    padding: 5px 30px 5px 20px;
    font-size: 16px;
    line-height: 25px;

}

.chat_message_container-block-in{
    margin-right: 15%;
    position: relative;
}


.chat_message_container-block-out{
    margin-left: 15%;
    position: relative;
}

.chat_message_container-text{
    background: #ECEDEF;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
}


.chat_message_container-text:before{
    background: url("../../../images/startForMessage.svg") no-repeat;
    content: ' ';
    width: 19px;
    height: 14px;
    position: absolute;
    left: -13px;
    top: 0px;
}


.chat_message_container-block-out .chat_message_container-text{
    background: #3B3F49;
    color: #fff;
}

.chat_message_container-block-out .chat_message_container-text:before{
    background: url("../../../images/startForMessageOut.svg") no-repeat;
    left: unset;
    right: -13px;
    bottom: 0px;
    top:unset;
    transform: rotate(180deg);

}

.chat_message_container-time{
    position: absolute;
    right: 10px;
    bottom: -3px;
    font-size: 12px;
    color: #999;
}



.chat_footer_container{
    background: #ECEDEF;
    height: 80px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
}


.chat_footer_container-button.active,
.attachment img.active
{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.attachment img,
.chat_footer_container-button  {
    border: unset;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}


.chat_footer_container-commands{

    position: fixed;
    bottom: 80px;
    right: 10px;
    overflow:hidden;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    -webkit-transition:  max-height 0.15s ease-out;
    -moz-transition:  max-height 0.15s ease-out;
    -o-transition:  max-height 0.15s ease-out;


}
.chat_footer_container-menu .chat-command-item:first-child{
    color: green;
    border: 1px solid green;
    margin-bottom: 10px;
}
.chat_footer_container-menu .chat-command-item{
    padding: 13px 15px;
}
.chat_footer_container-menu{
    left: 0px;
    right: 0px;
    background: #ffffff90;
    border-radius: 8px;
}

.chat_footer_container-commands.active{
    max-height: 300px;
    transition: max-height 0.25s ease-in;

}
.chat_footer_container-commands-container{
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.chat-command-item{
    color: #5364FF;
    padding: 15px;
    border: 1px solid #5364FF;
    border-radius: 4px;
    background: #fff;
}

.chat_footer_container-commands-container .chat-command-item,
.chat_footer_container-commands-container .chat-command-item:first-child{
    margin: unset;
    width: 48%;
    text-align: center;
    letter-spacing: -.46px;
}

.chat_footer_container input{
    padding: 5px 10px;
    width: 100%;
    border: unset;
    background: #ECEDEF;
    outline: unset;
    font-size: 17px;
    margin: 0 5px;
    margin-top: -3px;
}

.chat_footer_container input:focus{
    /*border: 1px solid #aaa;*/
    /*background: #f0f0f0;*/
}

.chat_message_container-is-read{
    background: url("../../../images/chekmark-gray.svg") no-repeat;
    position: absolute;
    left: 5px;
    top: 15px;
    width: 10px;
    height: 10px;
}

.chat_message_container-is-read.true{
    background: url("../../../images/chekmark.svg") no-repeat;
}

.chat_footer_container-button.attachment  {
    color: #a8a8a8;
    position: absolute;
    left: -5px;
    border: 1px solid #d8d8d8;

    padding: 7px 10px 6px 10px;
    border-radius: 7px;
    background: #fbfbfb;
}
.chat_footer_container-button.attachment img{
    width: 17px;
    height: 20px;
}


.make_photo_chat_window{
    position:fixed;
    background: #3B3F49;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    z-index: 100;
    color:#fff;
    font-family: Fira Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}


.commonContainer.chat_photo_message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.commonContainer.chat_photo_message .btn{
    margin: 10px 0;
}

#chat_file_input{
    visibility: hidden;
    height: 0;
}
.commonContainer.chat_file_message{
    justify-content: flex-end;
    padding: unset;
    height: auto;
}
.commonContainer.chat_file_message>img{
    border: 2px solid #eeeeee;
    border-radius: 16px;
    margin: 20px;
}

.chat_message_photo img{
    max-width: 100%;
    border-radius: 4px;
}

.chat_message_loading_photo{
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

.chat_message_container_fullscreen .chat_message_photo img{
    max-width: unset;
}
.chat_message_container_fullscreen{
    position: fixed;
    z-index: 111;
    top: 0;
    background: #00000080;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
    overflow: scroll;
}

.chat_message_container_fullscreen .window_close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 112;

    height: 36px;
    width: 36px;
    padding-top: 3px;
    border: 1px solid #a9a9a9;
    box-shadow: 0px 0px 2px #fff;
}
