.orders_window {
  background: #ECEDEF !important;
}

.orders_window_btn_container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  position: absolute;
  top: -1.25rem;
  left: 0;
  width: 100vw;
}

.orders_window_btn {
  background: #a8a8a8;
  opacity: 0.6;
  border-radius: 13px;
  height: 8px;
  width: 50px;
}

.orders_amount {
  box-sizing: border-box;

  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.94rem;
  letter-spacing: -0.408px;
  color: #5364FF;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.orders_sum {
  box-sizing: border-box;

  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: -0.408px;
  color: #000000;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0.25rem 0px;
}

.bottom_window_short_content .orders_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  width: 100%;
  max-width: 100%;

  overflow: scroll;
  padding: 1rem 0;
}

.bottom_window_full_content .orders_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  overflow: scroll;
  padding: 1rem;

  width: 100%;
}

.orders_tab_count {
  position: absolute;
  background: #00bb17;
  display: block;
  width: 18px;
  height: 18px;
  text-align: center;
  border-radius: 20px;
  right: -5px;
  top: -5px;
  font-size: 10px;
}
.orders_tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem;
}

.orders_tab {
  position: relative;

  cursor: pointer;
  background: #c6cad2;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;

  font-weight: 500;
  line-height: 1.466rem;
  color: #fff;

  text-transform: uppercase;
  margin-left: 0.8rem;
}

.orders_tab:first-child {
  margin-left: 0;
}

.orders_tabs .active {
  background: #3b3f49;
}



.order_block.loader-order{
  height: 160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c6cad2;
}

.order_block.empty-block{
  background:transparent;
  font-size:20px;
  color:#aaa;
  text-shadow: 1px 1px #fff;
}
