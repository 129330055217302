.order_play_info{
    font-style: normal;
    font-weight: normal;
    color: #888E99;
    font-size: 18px;
    letter-spacing: -0.408px;
    padding: 24px;
    border-top: 1px solid #aaa;

}

.order_play_info_number{
    font-weight: 500;
    line-height: 18px;
    color: #000;
    padding: 10px 0;
}

.order_play_info_time{
    line-height: 18px;
    padding: 10px 0;
}


.order_play_info_price_title{
    font-size: 15px;
    line-height: 20px;
    padding-top: 10px;
}

.order_play_info_price_value{
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    padding: 10px 0;
    color: #000;
}
