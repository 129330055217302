.chatBackground {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 350;
        background-color:  #00000030;
        top: 0;
        left: 0;
}
.commonContainer {
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 95vh;
    border-radius: 16px 16px 0px 0px;
    z-index: 355;
    left: 0;
}

.loadingForm{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
