.order_info_vehicle_requirements {
  background: #ecedef;
  border-radius: 8px;
  margin-top: 0.8rem;
}
.order_info_vehicle_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.35rem;
  box-sizing: border-box;
  padding: 0.8rem;
  width: 100%;
}

.vehicle_grid_item_name {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.85rem;
  line-height: 1.05rem;
}

.vehicle_grid_item_value_700,
.vehicle_grid_item_value {
  font-size: 1.05rem;
  line-height: 1.25rem;
  font-weight: 400;
  margin-top: 0.25rem;
}
.vehicle_grid_item_value_700{
  font-weight: 600;
}

.order_info_vehicle_header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order_info_vehicle_footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.8rem;
}

.order_info_vehicle_footer ul {
  margin: 0 0 0 1rem;
  padding: 0;
}
