.message_window{
    top: calc(50% - 200px);
    z-index: 136;
    position: absolute;
    background: #fff;
    border-radius: 16px;
    width: calc(100vw - 60px);
    padding: 20px;

    text-align: center;

    margin: 0 auto;

    left: 0;
    right: 0;

}

.message_bg{
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 360;
    position: fixed;
    top: 0;
}

.message_window_text, .message_window_text_error{
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

.message_window_text_error{
    color: #FF3131;
}
