.menu_btn {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 10;
}

.menu_window {
  position: fixed;
  top: 0;
  left: 0;

  background: #fff;
  border-radius: 0 16px 16px 0;

  height: 100vh;
  width: 80vw;

  z-index: 35;
}

.menu_window_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: #ecedef;
  padding: 1.4rem;
}

.user_avatar {
  border-radius: 50%;
  background-image: url('../../App//img/avatar.jpg');
  height: 4.25rem;
  width: 4.25rem;
  min-width: 4.25rem;
}

.user_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 1rem;
}

.user_fullname {
  font-size: 1.2rem;
}

.menu_items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
}

.menu_items a {
  color: #000;
  text-decoration: none;
}

.menu_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1rem 0 1.6rem;
}

.menu_item_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.menu_item_title {
  line-height: 1.4rem;
}

.menu_item_description {
  font-size: 0.85rem;
  line-height: 1.4rem;
  color: #888e99;
}

.version_info {
  position: absolute;
  bottom: 1.6rem;
  left: 1.6rem;
  line-height: 1.4rem;
  color: #888e99;
}
