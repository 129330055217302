.input_filtered_container .react-dadata__input{
    width: calc(100% - 2rem);
    margin: 0 1rem;
    font-family: 'Fira Sans';
    font-size: 1.1rem;
    text-align: left;
    border: none;
    outline: none;
    background: transparent;
}
