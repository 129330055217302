.input_filtered_container {
    width: 100%;
    margin: 0.4rem 0;
    line-height: 3.33rem;
    border: 1px solid #c6cad2;
    border-radius: 0.5rem;
    position: relative;
}

.input_filtered_full{
    width: 100%;
    margin-bottom: 10px;
}

.short {
    width: 48%;
}

.input_filtered_container.tiny {
    width: 31%;
}
.input_filtered_container:focus-within {
    border: 2px solid #5364ff;
    outline: none;
}


.input_filtered_container.complete{
    background: #ecedef;
}

.input_filtered_container.error {
    border-width: 2px!important;
    border-style: solid!important;
    border-color: red!important;
}



.input_long_short_text .input_filtered_text{
    font-size: 14px;
    width: calc(100% - 5px);
    padding: unset;
    margin: unset;
    padding-left: 7px;
    text-align: center;
}
.input_long_short_text2 .input_filtered_text{
    font-size: 1.1rem;
}
.input_filtered_text {
    width: calc(100% - 2rem);
    margin: 0 1rem;
    font-family: 'Fira Sans';
    font-size: 1.1rem;
    text-align: left;
    border: none;
    outline: none;
    background: transparent;
}

.input_filtered_container_name{
    padding-left: 5px;
    white-space: nowrap;
}

.input_filtered_container_sub_title{
    font-size: 0.7rem;
    font-style: italic;
    color: #aaa;
    padding-bottom: 10px;
    padding-left: 5px;
}
