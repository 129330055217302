
.point_card_block{

    position:relative;
}
.point_card_block:first-child{
    margin-top: unset;
}

.center{
    justify-content: center;
}
.center .order_info_point_detail{
    width: 100%;
}



.order_info_persons_buttons  .person_button{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 10px;
    align-items: center;
    text-align: left;
    padding-right: 25px;
}

.order_info_persons_buttons .first{
    border-right: 1px solid #aaa;
}

.point_card_block .order_info_persons{
    border-radius: 8px 8px 0 0;
    margin: 12px 0;
    margin-bottom: 0;
}

.order_info_persons_buttons {
    display: flex;
    justify-content: space-between;
    border: 1px solid #aaa;
    border-radius: 0 0 8px 8px;
    border-top: unset;
}





.decoration-line{
    border-left: 2px solid #9E9E9E;
    position: absolute;
    height: 100%;
    left: 30px;
    top: 50px;
}

.decoration-line__left{
    border-left: 2px solid blue;
}

.decoration-line__finish{
    height:25px;
    top:0px;
}


.point_card_block .point_number{
    background:#fff;
}

.point_card_block__current{
    background: #ECEDEF;
}

.point_card_block__current .btn_grey,
.point_card_block__current .order_info_persons,
.point_card_block__current .order_info_persons_buttons,
.point_card_block__current .contact_name{
    background:#fff;
}
.point_card_block__current .point_number,
.point_card_block__current .order_info_from span:first-child{
    background: #ECEDEF;
}

.point_card_block.first_block{
    border-radius: 16px 16px 0px 0px;
}
