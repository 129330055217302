
.question_window{
  width: calc( 100% - 1rem);
  margin: 0 auto;
  top: calc(50% - 125px);
  height: 250px;
  position: fixed;
  background: #ffff;
  border: 1px solid #eee;
  border-radius: 5px;
  left: 0.5rem;
  box-shadow: 1px 1px 4px #eeee;
  z-index: 33;
  padding: 20px;
}

.question_window_buttons{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.question_window_title{
  padding: 40px ;
  font-size: 1.3rem;
  text-align: center;

}


.question_window .btn{
  width: 100px;
}
