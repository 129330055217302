.order_info_cargo_card {
  background: #ecedef;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 1rem;
  margin-top: 0.8rem;
}

.order_info_cargo_title {
  font-weight: 500;
  line-height: 1.45rem;
}

.order_info_cargo_size {
  color: #888e99;
  font-size: 0.95rem;
  line-height: 1.45rem;
}