

.page_order_full_card{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 35;
  position: absolute;
  width: 100%;
  top:0 ;
  background: transparent;
  min-height: 100vh;
}


.page_order_full_card_content{
  border-radius: 10px 10px 0 0;
  background: #fff;
  min-height: calc(100vh - 44px);
}

.order_info_section {
  margin: 1rem 0;
}
.order_info_section_name {
  font-size: 1.2rem;
  font-weight: 500;
}

.order_info_section_name_description{
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #888E99;
  padding-top: 5px;
}
.order_info_roadmap_title {
  font-weight: 500;
  line-height: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
}

.order_info_roadmap {
  border-left: unset;
  /*margin-left: 0.5rem;*/
  /*padding-left: 2rem;*/
  padding: 0 14px;
  list-style: none;

}

.order_info_roadmap li {
  position: relative;
  margin-bottom: unset;
  padding-bottom: 30px;
  padding-top: 10px;
  border-left: 2px solid #5364ff;
  padding-left: 2rem;

}

.order_info_roadmap li:first-child{
  padding-top: unset;

}

.order_info_roadmap li:last-child{
  padding-top: unset;
  border-left: 2px solid #fff;
}

.order_info_roadmap li:last-child {
  margin-bottom: 0;
}
.order_info_roadmap li:before,
.order_info_roadmap li:first-child::before {

  content: '1';
  display: block;
  position: absolute;
  left: -1rem;
  background: #5364FF;
  /* border: 3px solid #5364ff; */
  border-radius: 50%;
  box-sizing: border-box;
  height: 1.8rem;
  text-align: center;
  width: 1.8rem;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  padding-top: 3px;
  border: unset;
}

.order_info_roadmap li:last-child::before {
  border: unset;
}

.order_info_roadmap li:before {
  content: ' ';
}


.order_info_roadmap_address {
  font-size: 1.05rem;
  font-weight: 500;
  line-height: 1.3rem;
}

.order-can-be-taken .order_info_roadmap_address{
  font-weight: normal;
}

.order_info_control_section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 0;
  text-align: center;
  margin-top: 30px;
}

.cancel_order{
  padding: 30px;
  font-weight: 100;
  color: #666;
  text-decoration: underline;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: lowercase;
}

.order_info_control_info {
  line-height: 1.25rem;
  color: #888e99;
  margin-bottom: 2rem;

}

.accepting_info {
  color: #888e99;
  font-size: 1rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.space{
  padding: 6px;
}

.noCargo{
  padding: 15px 0;
  text-align: center;
  font-size: 1.1rem;
  color: red;
}



.order_info_persons{
  padding: 12px;
  border: 1px solid #C6CAD2;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 12px 0;
}

.order_info_persons_title{
  color: #888;
  font-weight: 400;
  font-style: normal;
}

.order_info_one_person{
  font-size: 1.2rem;
  padding-top: 4px;
  color: #000;
}

.order_info_from span{
  background: #ECEDEF;
  padding: 8px 12px;
  display: inline-block;
  border-radius: 8px;
  margin-left: 10px;
  color: #000;
}
.order_info_from span:first-child{
  background:#fff;
  width: 60px;
  padding-left: unset;
  padding-right: unset;
  text-align: left;
  margin-left: unset;
  color: #888E99;
  font-size: 15px;
}

.order_info_from span.no-item{
  color: #888E99;
}

.order_info_from{
  margin: 12px 0;
  color: #888;
  font-size: 1.1rem;
  overflow: hidden;
}


.order_info_task{
  margin: 20px 0;
}
.order_info_task_header{
  font-weight: 500;
  font-size: 1.3rem;
}
.order_info_task_comment{
  color: #888E99;
  font-weight: 400;
}
.order_info_task_sm{
  padding: 0 0 10px 0;
}
.order_info_task_what_to_do{
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.408px;
  color: #000000;
}

.order_info_action{
  padding: 10px;
  border-radius: 8px;
  border: 1px solid;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 20px 0;
}

.link_show_point{
  padding: 15px 0  20px 0;
  text-align: center;
  text-decoration: underline;
  color: #888E99;
  font-size: 1.1rem;
}

.order-can-be-taken .link_show_point{
  display: none;
}

.full_content{
  margin-bottom: 20px;
}

.order_info_comment{
  padding: 20px 0;
  font-size: 18px;
  color: darkgreen;
}

.order_info_comment span{
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.order_info_datetime span{
  margin-left: 20px;
}
.order_info_datetime{
  font-size: 15px;
  color: #858585;
  padding-top: 5px;
  position: relative;
}
.flex-column{
  flex-direction: column !important;
}

.page_order_full_card .bottom_window_description{
  font-family: Fira Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;


  display: flex;
  align-items: center;
  letter-spacing: -0.408px;

  color: #888E99;
  padding-bottom: 20px;
}

.order_info_roadmap .number1:before { content: '1';}
.order_info_roadmap .number2:before { content: '2'; padding-left: 2px;}
.order_info_roadmap .number3:before { content: '3'; padding-left: 2px;}
.order_info_roadmap .number4:before { content: '4';}
.order_info_roadmap .number5:before { content: '5';}
.order_info_roadmap .number6:before { content: '6';}
.order_info_roadmap .number7:before { content: '7';}
.order_info_roadmap .number8:before { content: '8';}
.order_info_roadmap .number9:before { content: '9';}
.order_info_roadmap .number10:before { content: '10';}
.order_info_roadmap .number11:before { content: '11';}
.order_info_roadmap .number12:before { content: '12';}
.order_info_roadmap .number13:before { content: '13';}
.order_info_roadmap .number14:before { content: '14';}
.order_info_roadmap .number15:before { content: '15';}
.order_info_roadmap .number16:before { content: '16';}
.order_info_roadmap .number17:before { content: '17';}
.order_info_roadmap .number18:before { content: '18';}
.order_info_roadmap .number19:before { content: '19';}
.order_info_roadmap .number20:before { content: '20';}
.order_info_roadmap .number21:before { content: '21';}
.order_info_roadmap .number22:before { content: '22';}
.order_info_roadmap .number23:before { content: '23';}
.order_info_roadmap .number24:before { content: '24';}
.order_info_roadmap .number25:before { content: '25';}
.order_info_roadmap .number26:before { content: '26';}
.order_info_roadmap .number27:before { content: '27';}
.order_info_roadmap .number28:before { content: '28';}
.order_info_roadmap .number29:before { content: '29';}
.order_info_roadmap .number30:before { content: '30';}


.small-window .page_order_full_card_content{
  overflow: hidden;
}

.order_errors{
  padding: 19px 14px;

  font-style: normal;
  letter-spacing: -0.1px;
}
.order_errors__header{
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  text-transform: uppercase;
}
.order_errors__title{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #888E99;
  padding:5px 0;
}

.order_errors__block{
  padding-top:10px;

}

.order_errors__block_tem{
  background: #ECEDEF;
  border-radius: 8px;
  min-height: 44px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
  color:#FF3131;
  font-size: 14px;
  line-height: 20px;
  margin-bottom:10px;
  padding-left: 10px;
  padding-right: 20px;
  position: relative;
}

.order_errors__block_tem:after{
  content:' ';
  width:14px;
  height:14px;
  background:#FF3131;
  border-radius: 18px;
  position:absolute;
  right:10px;
}

.planTime{
  margin-left: 0px !important;
  font-family: monospace;
  letter-spacing: -1px;
}
.realTime{
  position: absolute;
  left: 0;
  top: 20px;
  margin-left: 0px !important;
  display: block;
  font-family: monospace;
  letter-spacing: -1px;
  color: blue;
}

.order_info_datetime{
  padding-bottom: 10px;
}

.order_info_datetime-words{
  font-size: 12px;
}

.order_info_persons.hidden_persons{
  display: none;
}
