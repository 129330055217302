.download_registration_form .page_message{
    padding: 80px 20px;
    font-size: 22px;
    line-height: 38px;
}


.download_registration_form .saving_button  {
    background: #00BB40;
    padding: 17px 10px;
    position: relative;
}

.download_registration_form .saving_button.next_button  {
    background: #5364ff;
    margin-top: 40px;
    padding: 17px 10px;

}

.download_registration_form .saving_button div:last-child {
    margin: unset;

}
.download_registration_form .page_title{
    text-align: center;
    margin-left: -6px;
}

.download_registration_form .saving_button .button_loader{
    position: absolute;
    left: 25px;
    margin: unset;
    margin-top: 6px;
}
